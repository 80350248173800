
import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon
} from 'mdbreact';

const Testimonials = ({title, desc, list}) => {
    return(
        <div>
            <h2 className="h1-responsive font-weight-bold my-5">
            {title}
            </h2>
            <p className="dark-grey-text w-responsive mx-auto mb-5">
            {desc}
            </p>

            <MDBRow className="text-center">
                {
                    list.map(item => {
                        return(
                            <MDBCol md="4" className="mb-md-0 mb-5">
                                <h3 className="font-weight-bold dark-grey-text mt-4">
                                    {item.name}
                                </h3>
                                <h5 className="font-weight-bold greenIcon my-3">
                                    {item.location}
                                </h5>
                                <p className="font-weight-normal dark-grey-text">
                                    <MDBIcon className="fa fa-quote-left pr-2" />
                                    {item.quote} <MDBIcon className="fa fa-quote-right pl-2" />
                                </p>
                            </MDBCol>
                        );
                    })
                }
            </MDBRow>
        </div>
    );
  }

export default Testimonials;