import React, { useEffect } from 'react';
import { MDBView, MDBContainer, MDBRow, MDBCol, MDBMask } from "mdbreact";
import Img from "gatsby-image"
import { navigate } from 'gatsby';


function onClickFunction(title) {
    const url = "/services/" + title;
    navigate(url)
}

function isTouch() {  
    try {  
      document.createEvent("TouchEvent");  
      return true;  
    } catch (e) {  
      return false;  
    }  
  }

const PortfolioSection = ({ services, header, description }) => {
    services.sort((a, b) => a.Order - b.Order);
    useEffect(() => {
        // Update the document title using the browser API
        if(isTouch() & screen.width < 700) {
            document.getElementById("masonry-with-columns-2").classList = "masonry-with-flex customGrid"
        }
      });
    return (
        <div>
            <a class="anchor" id="services"></a>
            <MDBContainer tag="section"  className="text-center my-5" >
                <h2 className="h1-responsive font-weight-bold my-5">
                    {header}
                </h2>
                <p className="lead grey-text w-responsive mx-auto mb-5">
                    {description}
                </p>
            </MDBContainer>
            <div className="masonry-with-columns-2 customGrid" id="masonry-with-columns-2">
                {
                    services.map(item => {
                        if (isTouch()) {
                            return(
                                <div>
                                    <a onClick={() => onClickFunction(item.title)}>
                                        <MDBView >
                                            <Img
                                                fluid={item.mainImage.childImageSharp.fluid}
                                                className="img-fluid"
                                            />
                                            <MDBMask className="flex-center" overlay="green-light">
                                                <p className="white-text h4-responsive">{item.title}</p>
                                            </MDBMask>
                                        </MDBView>
                                    </a>
                                </div>     
                            )
                        } else {
                            return (
                                <div>
                                    <a onClick={() => onClickFunction(item.title)}>
                                        <MDBView hover >
                                            <Img
                                                fluid={item.mainImage.childImageSharp.fluid}
                                                className="img-fluid"
                                            />
                                            <MDBMask className="flex-center" overlay="green-light">
                                                <p className="white-text h4-responsive">{item.title}</p>
                                            </MDBMask>
                                        </MDBView>
                                    </a>
                                </div>
                            );
                        }
                    })
                }
            </div>
        </div>
    );
}

export default PortfolioSection;