import React from "react";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer, MDBRow, MDBCol } from
    "mdbreact";
import Img from "gatsby-image"

const CarouselPage = ({ images, title, subtitle }) => {
    var count = 0;
    return (
            <MDBCarousel activeItem={1} length={3} className="w-100 test" id="home">
            <MDBCarouselInner>
                {
                    images.map(item => {
                        count = count + 1;
                        return (
                            <MDBCarouselItem itemId={count}>
                                <MDBView>
                                    <Img
                                        fluid={item.image.childImageSharp.fluid}
                                        className="test w-100"
                                        alt={item.description}
                                    />
                                    <MDBMask className="flex-center" overlay="black-strong">
                                    <MDBContainer id="intro">
                                        <MDBRow >
                                            <MDBCol md="12" className="white-text text-center">
                                                <h2 className="h1-responsive font-weight-bold white-text mb-0 pt-md-5 pt-5">{title}</h2>
                                                <hr className="hr-light my-4 w-75" />
                                                <h4 className="subtext-header h4-responsive mt-2 mb-4">{subtitle}</h4>
                                            </MDBCol>
                                        </MDBRow>
                                    </MDBContainer>
                                    </MDBMask>
                                </MDBView>
                            </MDBCarouselItem>
                        );
                    })
                }
            </MDBCarouselInner>
        </MDBCarousel>
    );
}

export default CarouselPage;
