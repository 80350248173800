import React from 'react';
import Layout from '../layout';
import Intro from './mask';
import SEO from '../seo';
import Carousel from './carousel'
import PortfolioSection from './portfolioSection';
import { MDBRow, MDBCol, MDBIcon, MDBContainer } from 'mdbreact'
import { graphql, useStaticQuery } from 'gatsby';
import ContactUs from './contactUs';
import Testimonials from './testimonials'

const query = graphql`
{
  strapiHome {
    headerOne
    headerOneDescription
    headerTwo
    headerTwoDescription
    images {
        image {
          childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    subtitle
    title
    aboutItem {
        icon
        description
        title
    }
    testimonials {
        location
        name
        quote
      }
    testText
    testTitle
  }
  allStrapiService {
      nodes {
          title
          Order
          mainImage {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
    }
    
}
`

const HomeComp = () => {
  const {strapiHome, allStrapiService} = useStaticQuery(query)
  //<Intro title={strapiHome.title} subtitle={strapiHome.subtitle} button={strapiHome.mainButton} />
  /*                {
<PortfolioSection header={strapiHome.headerOne} description={strapiHome.headerOneDescription} services={allStrapiService.nodes} />
*/
  return (
    <Layout navInfo={false} >
        <SEO title="Home" keywords={['Enironmental Approach']} />
        <Carousel images={strapiHome.images} title={strapiHome.title} subtitle={strapiHome.subtitle} />
        <main >
            <PortfolioSection header={strapiHome.headerOne} description={strapiHome.headerOneDescription} services={allStrapiService.nodes} />
            <section id="cardSection" className="text-center my-5 sectionCSS">
                <MDBContainer>
                <a class="anchor" id="about"></a>
                <h2 className="h1-responsive text-center font-weight-bold mb-5">
                    {strapiHome.headerTwo}
                </h2>
                <MDBRow>
                    {
                        strapiHome.aboutItem.map(item => {
                            return(
                                <MDBCol md="4">
                                    <MDBIcon icon={item.icon} size="3x" className="greenIcon" />
                                    <h3 className="font-weight-bold my-4">{item.title}</h3>
                                    <p className="grey-text mb-md-0 mb-5">
                                        {item.description}
                                    </p>
                                </MDBCol>
                            );
                        })
                    }
                </MDBRow>
                <Testimonials title={strapiHome.testTitle} desc={strapiHome.testText} list={strapiHome.testimonials} />
                </MDBContainer>
            </section>
            <ContactUs />
        </main>
  </Layout>
  )
  
}

export default HomeComp;
