import React, { Component } from 'react';
import HomeComp from '../components/Home/homeComp';


class App extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    var services = document.getElementById("services").offsetTop - 50;
    var about = document.getElementById("about").offsetTop - 50;
    var home = document.getElementById("home").offsetTop;
    var contact = document.getElementById("contact").offsetTop - 50;
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById("navbar").style.padding = "5px";
      document.getElementById("navbar").style.backgroundColor = "rgba(38, 66, 38, 1)";
    } else {
      document.getElementById("navbar").style.padding = "15px";
      document.getElementById("navbar").style.backgroundColor = "rgba(38, 66, 38, 0)";
    }
    if (document.body.scrollTop > contact || document.documentElement.scrollTop > contact) {
      document.getElementById("AboutNav").classList.remove("active");
      document.getElementById("ServicesNav").classList.remove("active");
      document.getElementById("HomeNav").classList.remove("active");
      document.getElementById("ContactNav").classList.add("active");
    } else if (document.body.scrollTop > about || document.documentElement.scrollTop > about) {
      document.getElementById("AboutNav").classList.add("active");
      document.getElementById("ServicesNav").classList.remove("active");
      document.getElementById("HomeNav").classList.remove("active");
      document.getElementById("ContactNav").classList.remove("active");
    } else if (document.body.scrollTop > services || document.documentElement.scrollTop > services) {
      document.getElementById("AboutNav").classList.remove("active");
      document.getElementById("ServicesNav").classList.add("active");
      document.getElementById("HomeNav").classList.remove("active");
      document.getElementById("ContactNav").classList.remove("active");
    } else if (document.body.scrollTop > services || document.documentElement.scrollTop > home) {
      document.getElementById("AboutNav").classList.remove("active");
      document.getElementById("ServicesNav").classList.remove("active");
      document.getElementById("HomeNav").classList.add("active");
      document.getElementById("ContactNav").classList.remove("active");
    }
  }
  render() {
    return (
      <>
        <HomeComp />
      </>
    );
  }
}

export default App;
